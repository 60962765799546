export const styleHideIconCardHeader = {
  opacity: "0",
};

export const styleShowIconCardHeader = {
  opacity: "1",
};

export const styleHideCardHeader = {
  paddingBottom: "0px",
  height: "0px",
};

export const styleShowCardHeader = {
  paddingBottom: "4px",
  height: "16px",
};
